import React, { Fragment } from 'react'
import { compose } from 'recompose'

import Layout from '../../components/Layout'
import ProfileForm from '../../components/Profile'
import {
  withAuthorization,
  withEmailVerification,
} from '../../components/Session'

const ProfilePageBase = () => (
  <Fragment>
    <h1>User Profile</h1>
    <ProfileForm />
  </Fragment>
)

const condition = authUser => !!authUser

const ProfilePage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(ProfilePageBase)

const Profile = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <ProfilePage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Profile
